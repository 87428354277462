import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import TextBody from '../components/text/TextBody'

const Text = ({
  data: {
    contentfulTextPage: {
      title,
      slug,
      seoTitle,
      seoDescription,
      qaItems,
      cta,
      ctaLink
    }
  }
}) => {
  return (
    <>
      <SEO slug={slug} title={seoTitle} description={seoDescription} isPage />
      <TextBody title={title} qaItems={qaItems} cta={cta} ctaLink={ctaLink} />
    </>
  )
}

export const PageQuery = graphql`
  query Text($node_locale: String!, $id: String!) {
    contentfulTextPage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      seoTitle
      seoDescription
      ...textPage
    }
  }
`

export default Text
