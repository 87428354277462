import React, { useState } from 'react'
import styled from 'styled-components'
import RichText from '../RichText'
import Icon from '../Icon'
import Table from './Table'
import {
  mobileVW,
  desktopVW,
  desktopBreakpoint
} from '../../styles'

const TextAccordion = ({ qaItems }) => {
  const [open, setOpen] = useState(0)

  const handleClick = (index) =>
    index === open ? setOpen(null) : setOpen(index)

  return (
    <>
      <AccordionWrapper>
        {qaItems.map(({ question, answer, table }, index) => (
          <Wrapper key={index}>
            <Question
              key={index}
              aria-label={question}
              selected={open === index}
              onClick={() => handleClick(index)}
            >
              <span>{question}</span>
              <div>
                <Icon name='arrow' />
              </div>
            </Question>
            <Answer show={open === index}>
              <RichText json={JSON.parse(answer.answer)} />
              {table && (
                <TableWrapper>
                  <Table tableData={table.tableData} />
                </TableWrapper>
              )}
            </Answer>
          </Wrapper>
        ))}
      </AccordionWrapper>
    </>
  )
}

const AccordionWrapper = styled.ul`
  padding: 0 ${mobileVW(40)} ${mobileVW(10)};
  height: 100%;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  justify-content: space-between;

  .hide-on-mobile {
    display: none;
  }

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVW(40)} 0 0;
    margin: auto;
    position: relative;
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;

    .hide-on-mobile {
      display: flex;
    }

    .hide-on-desktop {
      display: none;
    }
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const Question = styled.li`
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${mobileVW(16)} 0;

  span {
    font-size: ${mobileVW(22)};
    line-height: ${mobileVW(23)};
    flex: 0 1 auto;
    text-align: left;
    text-transform: initial;
    text-decoration: underline;
  }

  div {
    flex: 0 0 auto;
    height: auto;
    width: ${mobileVW(11)};
    margin-left: ${mobileVW(16)};
    ${({ selected }) => (selected ? 'transform: rotate(180deg)' : null)}
  }

  @media (min-width: ${desktopBreakpoint}) {
    justify-content: center;
    padding: ${desktopVW(35)} 0;

    span {
      font-size: ${desktopVW(26)};
      line-height: ${desktopVW(31)};
    }

    div {
      fheight: ${desktopVW(6)};
      width: ${desktopVW(11)};
      margin-left: ${desktopVW(16)};
    }
  }
`

const Answer = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  font-size: ${mobileVW(16)};
  line-height: ${mobileVW(26)};
  padding: ${mobileVW(10)} 0 ${mobileVW(20)};
  text-align: center;

  p {
    text-transform: initial;
    text-align: left;
    padding-bottom: ${mobileVW(22)};

    b {
      text-transform: initial;
    }

    &:last-of-type {
      padding-bottom: 0;
    }
  }

  li {
    margin-left: ${mobileVW(16)};
    list-style-type: disc;

    p {
      text-align: left;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(16)};
    line-height: ${desktopVW(26)};
    padding: 0 ${desktopVW(116)};

    p {
      text-align: center;
      padding-bottom: ${desktopVW(30)};

      &:last-of-type {
        padding-bottom: 0;
      }
    }

    li {
      margin-left: 0;
      
      p {
        text-align: left;
      }
    }
  }
`

const TableWrapper = styled.div`
  position: relative;
  overflow-x: scroll;
`

export default TextAccordion
