import React from 'react'
import styled from 'styled-components'
import {
  mobileVW,
  desktopVW,
  desktopBreakpoint,
  color,
  border
} from '../../styles'

const Table = ({ tableData }) => {
  const tableBody = tableData.reduce((acc, cur, index) => {
    if (index === 0) return acc
    const match = acc.length > 0
      ? acc.find(({ rowName }) => rowName === cur[0])
      : false

    if (match) {
      match.rows.push(cur)
      return acc
    }
    acc.push({
      rowName: cur[0],
      rows: [cur]
    })
    return acc
  }, [])

  return (
    <StyledTable>
      <thead>
        <tr>
          {tableData[0].map((head, index) => <th key={index}>{head}</th>)}
        </tr>
      </thead>
      <tbody>
        {tableBody.map(({ rows, rowName }) => (
          rows.map((cells, index) => (
            <React.Fragment key={index}>
              <tr>
                {index === 0 ? <td rowSpan={rows.length}>{rowName}</td> : null}
                {cells.map((cell, i) => {
                  if (i === 0) {
                    return null
                  } else {
                    return <td key={i}>{cell}</td>
                  }
                })}
              </tr>
            </React.Fragment>
          ))
        ))}
      </tbody>
    </StyledTable>
  )
}

const StyledTable = styled.table`
// border-collapse: collapse;
border-spacing: 0;
width: ${mobileVW(850)};
margin: 0 auto;
font-size: ${mobileVW(13)};
line-height: ${mobileVW(20)};

th,
td {
  border: ${border.thin};
  width: ${mobileVW(140)};
  padding: ${mobileVW(7)};
}

th, td {
  border-bottom: none;
}

tr:last-child td {
  border-bottom: ${border.thin};
}

@media (min-width: ${desktopBreakpoint}) {
  width: ${desktopVW(850)};
  font-size: ${desktopVW(12)};
  line-height: ${desktopVW(19)};

  th,
  td {
    width: ${desktopVW(140)};
    padding: ${desktopVW(7)};
  }
}
`

export default Table
