import React from 'react'
import styled from 'styled-components'
import TextAccordion from './TextAccordion'
import { SiteLink } from '../SiteLink'
import {
  desktopVW,
  desktopBreakpoint,
  mobileVW,
  headerHeight
} from '../../styles'

const TextBody = ({ title, qaItems, cta, ctaLink }) => {
  return (
    <TextBodyWrapper>
      <Title>{title}</Title>
      <TextAccordion qaItems={qaItems} />
      <Cta slug={ctaLink.link[0].slug}>{cta}</Cta>
    </TextBodyWrapper>
  )
}

const TextBodyWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: calc(${headerHeight.mobile} + ${mobileVW(30)}) 0 ${mobileVW(30)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 auto;
    width: calc(100% - ${desktopVW(140)});
    padding: calc(${headerHeight.desktop} + ${desktopVW(60)}) ${desktopVW(120)};
  }
`

const Title = styled.h1`
  font-size: ${mobileVW(60)};
  line-height: ${mobileVW(51)};
  padding: ${mobileVW(30)} ${mobileVW(10)} ${mobileVW(40)};
  word-break: break-all;
  position: relative;
  width: 100%;
  text-align: center;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(150)};
    line-height: ${desktopVW(142)};
    padding: 0 ${desktopVW(100)};
    word-break: break-word;
  }
`

const Cta = styled(SiteLink)`
  font-size: ${mobileVW(30)};
  line-height: ${mobileVW(31)};
  padding-top: ${mobileVW(60)};
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
  font-weight: normal;
  text-decoration: underline;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(30)};
    line-height: ${desktopVW(31)};
    padding-top: ${desktopVW(60)};
  }
`

export default TextBody
